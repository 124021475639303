export default {
  data() {
    return {
      pageInfo: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  methods: {
    hanldeSearch(searchParams) {
      this.pageInfo.pageNum = 1;
      this.getDataList(searchParams);
    },
    handleChangePage(page) {
      this.pageInfo = page;
      this.getDataList();
    },
    getDataList(searchParams = {}) {
      this.$store.dispatch("meter/getMeterManageListAction", {
        pageName: this.pageName,
        params: { ...this.pageInfo, ...searchParams, meter_type: this.type },
      });
    },
  },
};
