<template>
  <div class="content">
    <hy-table :listData="dataList" v-bind="contentTableConfig" :dataCount="dataCount" :page="pageInfo"
      @update:page="handleChangePage">
      <template #header>
        <PageHeader :search-list="searchList" @search="hanldeSearch" :pageName="pageName">
          <template #btn>
            <slot name="btn"></slot>
          </template>
        </PageHeader>
      </template>
      <!-- 通用插槽 -->
      <template #status="scope"></template>
      <template #handler="scope">
        <pop popLeft tips="详情">
          <img class="icon" src="@/assets/img/icon/xiangqing.png" alt="" />
        </pop>
      </template>
      <template v-for="(item, index) of otherPropSlots" #[item.slotName]="scope">
        <template v-if="item.slotName">
          <slot :name="item.slotName" :row="scope.row"></slot>
        </template>
      </template>
    </hy-table>
  </div>
</template>
<script>
import HyTable from "@/components/hy-table.vue";
import PageHeader from "./pageHeader.vue";
export default {
  components: {
    HyTable,
    PageHeader,
  },
  props: {
    contentTableConfig: {
      type: Object,
      require: true,
    },
    pageName: {
      type: String,
      require: true,
    },
    pageInfo: {
      type: Object,
      default: () => ({}),
    },
    searchList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    otherPropSlots () {
      const data = this.contentTableConfig?.propList.filter((item) => {
        // 过滤通用插槽
        if (item.slotName === "status") return false;
        if (item.slotName === "handler") return false;
        return true;
      });
      return data;
    },
    dataList () {
      return this.$store.getters[`meter/pageListData`](this.pageName);
    },
    dataCount () {
      return this.$store.getters[`meter/pageCountData`](this.pageName);
    },
  },
  data () {
    return {};
  },
  methods: {
    handleChangePage (page) {
      this.$emit("update:page", page);
    },
    hanldeSearch (serrchForm) {
      this.$emit("search", serrchForm);
    },
  },
};
</script>

<style scoped></style>
