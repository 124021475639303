<template>
  <el-form :inline="true">
    <el-form-item v-for="(searchItem, index) of searchList" :key="index" :label="searchItem.label">
      <!-- <el-input
        v-if="searchItem.type == 'input'"
        :placeholder="searchItem.placeholder"
        v-model="searchParams[searchItem.field]"
      /> -->
      <MyInput v-if="searchItem.type == 'input'" v-model="searchParams[searchItem.field]"
        :placeholder="searchItem.placeholder">
        <template slot="pre">{{ searchItem.insideLabel }}</template>
      </MyInput>
      <el-radio-group v-if="searchItem.type == 'radio'" v-model="searchParams[searchItem.field]">
        <el-radio v-for="item of searchItem.options" :label="item.label" :key="item.label">{{ item.text }}</el-radio>
      </el-radio-group>
      <el-select v-if="searchItem.type == 'select'" v-model="searchParams[searchItem.field]" clearable
        :placeholder="searchItem.placeholder">
        <el-option :label="item.label" :value="item.value" :key="index"
          v-for="(item, index) of searchItem.options"></el-option>
      </el-select>
      <el-date-picker v-if="searchItem.type == 'daterange'" v-model="searchParams[searchItem.field]" @change="timeChange"
        type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <MyButton title="搜索" @click="currentChange()">
        <template slot="preImage">
          <img src="@/unit/img/search.png" alt="" />
        </template>
      </MyButton>
      <MyButton title="导出" :left="true" @click="exportAll"
        v-if="pageName == 'rechargeRecord' || pageName == 'water_rechargeRecord'">
        <template slot="preImage">
          <img src="@/unit/img/down.png" alt="" />
        </template>
      </MyButton>
      <slot name="btn"></slot>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: {
    searchParams: {
      type: Object,
      default: () => ({}),
    },
    searchList: {
      type: Array,
      default: () => [],
    },
    pageName: {
      type: String,
      default: () => '',
    }
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {
    timeChange (date) {
      if (date) {
        this.searchParams.beginTime = date[0];
        this.searchParams.endTime = date[1];
      } else {
        this.searchParams.beginTime = "";
        this.searchParams.endTime = "";
      }
    },
    exportAll () {
      this.$request.dailyPost('/meterItemPay/export', {
        ...this.searchParams,
        meter_type: this.pageName == 'water_rechargeRecord' ? 1 : 2
      }, { responseType: "blob" }).then((res) => {
        let blob = new Blob([res]);
        let link = document.createElement("a");
        let url = URL.createObjectURL(blob);
        link.href = url;
        link.download = '充值记录.xlsx';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }).catch((err) => {
        console.log("导出失败", err);
      });
    },
    currentChange () {
      this.$emit("search", this.searchParams);
    },
  },
};
</script>
<style lang="scss" scoped></style>
