<template>
  <el-dialog :title="title" :visible.sync="visible" :close-on-click-modal="false" :width="width"
    @close="hanldeDialogClose">
    <slot name="content">
      <el-form ref="formRef" :model="formData" :label-width="labelWidth" label-position="right">
        <!-- requireRules 是否一直校验-->
        <el-form-item :label="formItem.label" v-for="(formItem, index) of formFieldList" :key="index"
          :rules="formItem.requireRules || isCheck ? formItem.rules : []" :prop="formItem.field">
          <el-input v-model="formData[formItem.field]" v-if="formItem.type == 'input'" :placeholder="formItem.placeholder"
            @blur="hanldeblur(formItem.field)" :disabled="formItem.disabled" />
          <el-input v-model="formData[formItem.field]" type="textarea" v-if="formItem.type == 'textarea'"
            :placeholder="formItem.placeholder" :disabled="formItem.disabled" />
          <el-select v-if="formItem.field == 'merchants_id' && formItem.type == 'select'" filterable style="width: 100%"
            v-model="formData[formItem.field]" clearable :placeholder="formItem.placeholder"
            @change="changeMerchantsId($event, formItem)" :disabled="formItem.disabled">
            <el-option :label="item.label" :value="item.value" :key="index"
              v-for="(item, index) of selectOptions[formItem.field]"></el-option>
          </el-select>
          <el-select v-if="formItem.field != 'merchants_id' && formItem.type == 'select'" filterable style="width: 100%"
            v-model="formData[formItem.field]" clearable :placeholder="formItem.placeholder"
            @change="changeType($event, formItem)" :disabled="formItem.disabled">
            <el-option :label="item.label" :value="item.value" :key="index"
              v-for="(item, index) of selectOptions[formItem.field]"></el-option>
          </el-select>
          <el-autocomplete v-if="formItem.type == 'autocomplete'" v-model="formData[formItem.field]" clearable
            :fetch-suggestions="formItem.autocompleteFn" placeholder="商户名称" />
          <div v-if="formItem.type == 'radio'">
            <el-radio-group v-model="formData[formItem.field]" @change="hanldeChange">
              <el-radio v-for="item of formItem.options" :label="item.label" :key="item.label">{{ item.text }}</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-form>
    </slot>
    <template slot="footer">
      <slot name="footer">
        <MyButton @click="close">取消</MyButton>
        <MyButton type="primary" left @click="submit">确认</MyButton>
      </slot>
    </template>
  </el-dialog>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "默认标题",
    },
    formFieldList: {
      type: Array,
      default: () => [],
    },
    sorceFormData: {
      type: Object,
      default: () => ({}),
    },
    labelWidth: {
      type: String,
      default: "120px",
    },
    width: {
      type: String,
      default: "520px",
    },
    selectOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    sorceFormData: {
      handler (newValue) {
        console.log("newValue", newValue);
        this.formData = JSON.parse(JSON.stringify(newValue));
      },
      deep: true,
    },
  },
  data () {
    return {
      visible: false,
      formData: {},
      isCheck: false,
    };
  },
  methods: {
    hanldeblur (filed) {
      this.$emit(filed, this.formData[filed]);
    },
    changeMerchantsId (event, item) {
      let data = this.selectOptions[item.field].filter((list) => {
        if (list.merchants_id == event) {
          return list;
        }
      });
      if (data) {
        this.formData.merchants_name = data[0].merchants_name;
        this.formData.merchants_no = data[0].merchants_number;
      } else {
        this.formData.merchants_name = '';
        this.formData.merchants_no = '';
      }
    },
    changeType (event, item) {
      let data = this.selectOptions[item.field].filter((list) => {
        if (list.merchants_stall_id == event) {
          return list;
        }
      });
      if (data) {
        this.formData.merchants_stall_name = data[0].merchants_stall_number
      } else {
        this.formData.merchants_stall_name = '';
      }
    },
    hanldeDialogClose () {
      this.formData = {};
      this.$emit("close");
    },
    hanldeChange (value) {
      if (value == 2) {
        this.isCheck = false;
        this.$refs.formRef?.clearValidate();
      } else {
        this.isCheck = true;
      }
    },
    close () {
      this.visible = false;
      this.isCheck = false;
    },
    open () {
      this.$refs.formRef?.clearValidate();
      this.visible = true;
    },
    async submit () {
      try {
        await this.$refs.formRef?.validate();
        this.$emit("submit", this.formData);
      } catch (error) {
        console.log(error, "校验失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
