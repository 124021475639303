<template>
  <div class="hy-table">
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="content">
      <el-table :data="listData" style="width: 100%" @selection-change="handleSelectionChange" :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }" :cell-style="{ 'text-align': 'center' }" v-bind="childrenProps">
        <el-table-column v-if="showSelectColumn" align="center" type="selection" width="50">
        </el-table-column>
        <el-table-column v-if="showIndexColumn" type="index" align="center" label="序号" width="60"></el-table-column>
        <el-table-column v-for="(propItem, index) in propList" :key="index" v-bind="propItem" align="center"
          show-overflow-tooltip>
          <template #default="scope">
            <slot :name="propItem.slotName" :row="scope.row">
              {{ scope.row[propItem.prop] }}</slot>
          </template></el-table-column>
      </el-table>
    </div>
    <div class="footer" v-if="showFooter">
      <el-pagination :total="dataCount" :current-page="page.pageNum" :page-size="page.pageSize"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total,prev,pager,next"
        next-text="下一页" prev-text="上一页">
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    propList: {
      type: Array,
      required: true,
    },
    listData: {
      type: Array,
      required: true,
    },
    dataCount: {
      type: Number,
      default: 0,
    },
    showIndexColumn: {
      type: Boolean,
      default: false,
    },
    showSelectColumn: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Object,
      default: () => ({ pageNum: 1, pageSize: 10 }),
    },
    childrenProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {};
  },
  watch: {
    listData (newValue) {
      //处理删除当前页最后一个
      if (!newValue.length && this.page.pageNum > 1) {
        this.handleCurrentChange(this.page.pageNum - 1);
      }
    },
  },
  methods: {
    handleSelectionChange (value) {
      this.$emit("selectionChange", value);
    },
    handleSizeChange (pageSize) {
      this.$emit("update:page", { ...this.page, pageSize });
    },
    handleCurrentChange (pageNum) {
      this.$emit("update:page", { ...this.page, pageNum });
    },
  },
};
</script>
<style lang="scss" scoped>
.hy-table {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  height: 80%;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
</style>
